// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biznes-jsx": () => import("./../../../src/pages/biznes.jsx" /* webpackChunkName: "component---src-pages-biznes-jsx" */),
  "component---src-pages-dla-mediow-jsx": () => import("./../../../src/pages/dla-mediow.jsx" /* webpackChunkName: "component---src-pages-dla-mediow-jsx" */),
  "component---src-pages-doradztwo-jsx": () => import("./../../../src/pages/doradztwo.jsx" /* webpackChunkName: "component---src-pages-doradztwo-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-polityka-prywatnosci-jsx": () => import("./../../../src/pages/polityka-prywatnosci.jsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-jsx" */),
  "component---src-pages-rdb-media-jsx": () => import("./../../../src/pages/rdb-media.jsx" /* webpackChunkName: "component---src-pages-rdb-media-jsx" */),
  "component---src-pages-rdb-socials-jsx": () => import("./../../../src/pages/rdb-socials.jsx" /* webpackChunkName: "component---src-pages-rdb-socials-jsx" */),
  "component---src-pages-reklama-dla-biznesu-jsx": () => import("./../../../src/pages/reklama-dla-biznesu.jsx" /* webpackChunkName: "component---src-pages-reklama-dla-biznesu-jsx" */),
  "component---src-pages-reklama-jsx": () => import("./../../../src/pages/reklama.jsx" /* webpackChunkName: "component---src-pages-reklama-jsx" */),
  "component---src-pages-ruby-studio-jsx": () => import("./../../../src/pages/ruby-studio.jsx" /* webpackChunkName: "component---src-pages-ruby-studio-jsx" */),
  "component---src-pages-webclever-jsx": () => import("./../../../src/pages/webclever.jsx" /* webpackChunkName: "component---src-pages-webclever-jsx" */)
}

